import { LOCATION_SET, PRODUCT_SET } from 'constants/actionType'
import { request } from 'utilities/request'

export async function getProducts(local) {
  const { productHash } = local.state
  const [ok, data] = await request({
    local,
    url: process.env.REACT_APP_PRODUCT_URL,
    body: { action: 'find', data: { hash: productHash } },
  })
  if (!ok) return {}
  return data
}

export function getProductOptions(local) {
  const { products } = local.state
  return products.map((item) => ({ value: item.id, label: item.name }))
}

export async function getLocations(local) {
  const { locationHash } = local.state
  const [ok, data] = await request({
    local,
    url: process.env.REACT_APP_LOCATION_URL,
    body: { action: 'find', data: { hash: locationHash } },
  })
  if (!ok) return {}
  return data
}

export function getLocationOptions(local, types) {
  return local.state.locations
    .filter((item) => types.includes(item.type))
    .map((item) => ({
      value: item.id,
      label: item.name,
    }))
}

export async function updateConfigurables(local) {
  const { productHash, locationHash } = local.state

  const productResp = await getProducts(local)
  if (!productResp.hash || productResp.hash !== productHash) {
    local.dispatch({ type: PRODUCT_SET, ...productResp })
  }

  const locationResp = await getLocations(local)
  if (!locationHash || locationResp.hash !== locationHash) {
    local.dispatch({ type: LOCATION_SET, ...locationResp })
  }
}

export const setBalances = (ticketItems, balances, key = 'balance') => {
  ticketItems.forEach((ticketItem) => {
    const { productId, expireDate, batchNo } = ticketItem
    const balance = balances.find(
      (i) =>
        i.productId === productId &&
        i.expireDate === expireDate &&
        i.batchNo === batchNo,
    )
    ticketItem[key] = balance ? balance.quantity : 0
  })
}
