export const ALERT_ADD = 'ALERT_ADD'
export const ALERT_REMOVE = 'ALERT_REMOVE'
export const LOADING_SHOW = 'LOADING_SHOW'
export const LOADING_HIDE = 'LOADING_HIDE'
export const SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE'
export const PAGE_SET = 'PAGE_SET'
export const TOKEN_SET = 'TOKEN_SET'
export const TOKEN_REMOVE = 'TOKEN_REMOVE'
export const USER_SET = 'USER_SET'
export const CONFIRM_OPEN = 'CONFIRM_OPEN'
export const CONFIRM_CLOSE = 'CONFIRM_CLOSE'
export const PRODUCT_SET = 'PRODUCT_SET'
export const LOCATION_SET = 'LOCATION_SET'
export const STAFF_SET = 'STAFF_SET'
export const FILTER_SET = 'FILTER_SET'
export const PAGINATION_SET = 'PAGINATION_SET'
