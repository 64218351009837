const shelf = {
  'module.shelf': '上架商品',
  'shelf.title.list': '上架商品',
  'shelf.title.add': '新增上架商品',
  'shelf.title.edit': '修改上架商品',
  'shelf.title.view': '上架商品詳情',
  'shelf.field.name': '商品名稱',
  'shelf.field.product': '關聯庫存商品',
  'shelf.field.summary': '商品特色',
  'shelf.field.spec': '商品規格',
  'shelf.field.detail': '詳細說明',
}

export default shelf
