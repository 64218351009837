import cx from 'classnames'
import { useNavigate } from 'react-router-dom'
import { Message } from 'components'
import style from './Link.module.css'

const Link = ({
  className = {},
  show = true,
  disabled = false,
  label,
  text,
  href,
  icon,
  leftIcon,
  rightIcon,
  children,
  onClick,
  ...props
}) => {
  const navigate = useNavigate()
  if (!show) return null
  if (!leftIcon) leftIcon = icon

  const getClickProp = () => {
    if (disabled) return { onClick: () => {} }
    if (href) {
      if (href.startsWith('http')) return { href }
      return { onClick: () => navigate(href) }
    }
    return { onClick: () => onClick(navigate) }
  }

  const renderText = () => {
    if (label) {
      return <Message as="div" className={className['link__text']} id={label} />
    }

    if (text) {
      return <div className={className['link__text']}>{text}</div>
    }

    return children
  }

  return (
    <a
      className={cx(style.link, className.link, className['link--active'])}
      {...getClickProp()}
      {...props}
    >
      {leftIcon && (
        <div
          className={cx(
            style['link__icon'],
            style['link__icon--left'],
            className['link__icon'],
            className['link__icon--left'],
          )}
        >
          {leftIcon}
        </div>
      )}
      {renderText()}
      {rightIcon && (
        <div
          className={cx(
            style['link__icon'],
            style['link__icon--right'],
            className['link__icon'],
            className['link__icon--right'],
          )}
        >
          {rightIcon}
        </div>
      )}
    </a>
  )
}

export default Link
