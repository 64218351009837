const refund = {
  'module.refund': '退貨單',
  'refund.toLocationId.required': '倉別為必填欄位',
  'refund.title.list': '退貨目錄',
  'refund.title.add': '新增退貨',
  'refund.title.edit': '修改退貨',
  'refund.title.view': '退貨詳情',
  'refund.title.input': '退貨點收',
  'refund.section.applied': '退貨商品',
  'refund.section.received': '點收商品',
  'refund.section.batchItem': '點收商品',
  'refund.field.id': '單號',
  'refund.field.ticketNo': '原始單號',
  'refund.field.contactName': '退貨人名稱',
  'refund.field.receiveType': '類別',
  'refund.field.appliedQuantity': '退貨數量',
  'refund.field.receivedQuantity': '點收數量',
  'refund.field.batchNo': '批號',
  'refund.receiveType.DISCARD': '瑕疵報廢',
  'refund.receiveType.RECEIVE_LESS': '數量短收',
}

export default refund
