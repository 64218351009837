import { Message, TextInput, DateInput, Select } from 'components/'
import { COUNT_PER_PAGE } from './paginationAction'

export function handleSubmit({
  filterValues,
  filterInputs,
  filterSelect,
  onSubmit,
}) {
  const filterInput = filterInputs[filterSelect.value]
  if (!filterInput || filterInput.length === 0) return false

  const filterVals = [...filterValues]
  const newFilter = {
    id: filterSelect.value,
    label: filterSelect.label,
    value: filterInput,
  }
  const index = filterVals.findIndex(({ id }) => filterSelect.value === id)
  if (index === -1) {
    filterVals.push(newFilter)
  } else {
    filterVals.splice(index, 1, newFilter)
  }

  return onSubmit({ filterValues: filterVals })
}

export function getInitFilterInputs(filters) {
  if (!filters) return {}

  return filters.reduce((result, config) => {
    result[config.id] = config.defaultValue || ''
    return result
  }, {})
}

export function getInitFilterSelect(filters, message) {
  if (!filters || filters.length === 0) return {}

  const defaultFilter = filters[0]
  return {
    value: defaultFilter.id,
    label: message({ id: defaultFilter.label }),
  }
}

export function getInitFilterVals(session) {
  const { pageId, filterMap } = session.state
  return filterMap[pageId] || []
}

export function getFilterLabel(filters, { id, value }) {
  const filter = filters.find((item) => item.id === id)
  if (filter.getLabel) return filter.getLabel(value)
  const getValue = () => {
    if (typeof value === 'string') return value
    if (Array.isArray(value)) return value.join(', ')
    if (value.label) return value.label
    return ''
  }
  return (
    <>
      <Message style={{ marginRight: 'var(--space-2)' }} id={filter.label} />
      {getValue(value)}
    </>
  )
}

export const textFilter = ({ ref, filterInput, setFilterInput }) => (
  <TextInput ref={ref} value={filterInput || ''} onChange={setFilterInput} />
)

export const selectFilter = ({
  ref,
  filterInput,
  setFilterInput,
  isSearchable = false,
  options,
}) => (
  <Select
    ref={ref}
    isSearchable={isSearchable}
    isClearable={false}
    options={options}
    value={filterInput}
    onChange={(item) => setFilterInput(item)}
  />
)

export const dateFilter = ({ ref, filterInput, setFilterInput }) => {
  const [fromDate, toDate] = filterInput || [null, null]
  return (
    <div>
      <DateInput
        ref={ref}
        mr={2}
        fieldProps={{ m: 0, flex: 1, width: 1 }}
        min="1970-01-01"
        value={fromDate}
        onChange={(value) => setFilterInput([value, toDate])}
      />
      <DateInput
        fieldProps={{ m: 0, flex: 1, width: 1 }}
        min="1970-01-01"
        value={toDate}
        onChange={(value) => setFilterInput([fromDate, value])}
      />
    </div>
  )
}

export function filterRows(data, pagination = {}, filterValues = []) {
  const { page = 1, countPerPage = COUNT_PER_PAGE } = pagination
  let filteredData = data

  if (filterValues.length > 0) {
    filteredData = data.filter((item) => {
      let count = 0
      filterValues.forEach(({ id, value }) => {
        if (item[id].indexOf(value) !== -1) count++
      })
      return count === filterValues.length
    })
  }

  const startIdx = (page - 1) * countPerPage
  return {
    count: filteredData.length,
    data: filteredData.slice(startIdx, startIdx + countPerPage),
  }
}
