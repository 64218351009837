import cx from 'classnames'
import style from './Switch.module.css'

const Switch = ({ disabled = false, checked, onClick, ...props }) => {
  const getColor = () => {
    if (checked) {
      return disabled ? 'var(--color-accent-0)' : 'var(--color-accent-1)'
    }
    return disabled ? 'var(--color-light-3)' : 'var(--color-dark-0)'
  }

  return (
    <label className={style.switch} {...props}>
      <input
        className={style['switch__checkbox']}
        type="checkbox"
        onClick={disabled ? () => {} : onClick}
      />
      <span
        className={cx(style['switch__indicator'], {
          [style['switch__indicator--active']]: checked,
        })}
        style={{
          backgroundColor: getColor(),
          cursor: disabled ? 'disabled' : 'pointer',
        }}
      />
    </label>
  )
}

export default Switch
