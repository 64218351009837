const merchant = {
  'module.merchant': '商家資料',
  'merchant.title.edit': '一般設定',
  'merchant.field.brand': '網站名稱',
  'merchant.field.name': '公司名稱',
  'merchant.field.email': '公司Email',
  'merchant.field.phone': '公司電話',
  'merchant.field.address': '公司地址',
  'merchant.field.headerLogo': 'Header Logo',
  'merchant.field.footerLogo': 'Footer Logo',
  'merchant.field.color': '網站背景顏色',
}

export default merchant
