import { useRef, forwardRef, useImperativeHandle } from 'react'
import cx from 'classnames'
import useMessage from 'hooks/useMessage'
import { Field } from 'components'
import style from './TextInput.module.css'

export const TextInput = (
  { id, label, placeholder, required, errMsg, onChange, fieldProps, ...props },
  ref,
) => {
  const message = useMessage()
  const inputRef = useRef()
  const hasError = !!errMsg

  useImperativeHandle(ref, () => ({
    focus: () => inputRef.current && inputRef.current.focus(),
  }))

  return (
    <Field
      id={id}
      label={label}
      required={required}
      errMsg={errMsg}
      {...fieldProps}
    >
      <input
        className={cx(
          style['text-input'],
          hasError && style['text-input--error'],
        )}
        ref={inputRef}
        placeholder={message(placeholder)}
        autoComplete="off"
        onChange={({ target }) => onChange(target.value)}
        onBlur={({ target }) => onChange(target.value?.trim())}
        {...props}
      />
    </Field>
  )
}

export default forwardRef(TextInput)
