import cx from 'classnames'
import { Link } from 'components'
import style from './Breadcrumb.module.css'

const Breadcrumb = ({ className = {}, items, ...props }) => {
  if (!items) return null

  return (
    <ul className={cx(style.breadcrumb, className.container)} {...props}>
      {items.map((item, index) => (
        <li key={index} className={style['breadcrumb__item']}>
          {item.href ? (
            <Link href={item.href}>{item.label}</Link>
          ) : (
            <div style={{ color: 'var(--color-dark-0)' }}>{item.label}</div>
          )}
        </li>
      ))}
    </ul>
  )
}

export default Breadcrumb
