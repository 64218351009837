export function getStatusColor(status) {
  switch (status) {
    case 'ACTIVE':
      return 'var(--color-success-1)'
    case 'PENDING':
      return 'var(--color-warning-1)'
    default:
      return 'var(--color-dark-0)'
  }
}
