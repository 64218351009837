import { useEffect } from 'react'
import cx from 'classnames'
import { useNavigate } from 'react-router-dom'
import useGlobalState from 'hooks/useGlobalState'
import { Message, Button, LoadingSpinner } from 'components'
import { useCallbackOnce } from 'hooks/useCallbackOnce'
import { MdClose } from 'react-icons/md'
import style from './Modal.module.css'

const Modal = ({
  className = {},
  width = 300,
  title,
  profile,
  open,
  onClose = () => {},
  onLoad,
  onSubmit,
  showSubmit = true,
  disableSubmit = false,
  children,
  ...props
}) => {
  const { session, local } = useGlobalState()
  const navigate = useNavigate()
  const pageState = { session, local, profile, navigate }

  useEffect(() => {
    if (open && onLoad) onLoad(pageState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const { loading } = session.state
  const Tag = onSubmit ? 'form' : 'div'
  const handleSubmit = async (event) => {
    event.preventDefault()
    if (!onSubmit) return false
    await onSubmit(pageState)
  }

  return (
    <>
      <div
        className={style['modal__overlay']}
        style={{
          transform: open ? 'scale(1)' : 'scale(0)',
          opacity: open ? 0.6 : 0,
        }}
      />
      <div
        className={style['modal__wrap']}
        style={{
          transform: open ? 'translateY(0px)' : 'translateY(110vh)',
          opacity: open ? 1 : 0,
        }}
      >
        <Tag
          className={style.modal}
          style={{ width }}
          onSubmit={useCallbackOnce(handleSubmit)}
          {...props}
        >
          <div className={cx(style['modal__header'], className.header)}>
            <Message
              className={cx(style['modal__title'], className.title)}
              id={title}
            />
            <Button
              disabled={loading}
              icon={<MdClose size="24px" />}
              onClick={onClose}
            />
          </div>
          <div className={cx(style['modal__content'], className.content)}>
            {children}
          </div>
          <div className={style['modal__footer']}>
            <Button
              className={{ button: style['modal__footer__cancel'] }}
              disabled={loading}
              label="btn.close"
              onClick={onClose}
            />
            <Button
              className={{ button: style['modal__footer__submit'] }}
              rightIcon={loading ? <LoadingSpinner /> : null}
              show={showSubmit}
              disabled={loading || disableSubmit}
              type="submit"
              label="btn.confirm"
            />
          </div>
        </Tag>
      </div>
    </>
  )
}

export default Modal
