import {
  LOADING_SHOW,
  LOADING_HIDE,
  ALERT_ADD,
  TOKEN_SET,
  TOKEN_REMOVE,
} from 'constants/actionType'

export async function request({
  session,
  local = {},
  method = 'POST',
  url,
  body,
}) {
  try {
    const { token } = local.state || {}
    if (session) session.dispatch({ type: LOADING_SHOW })

    const headers = {
      'Content-Type': 'application/json',
      'xiv-auth-type': token ? 'jwt' : 'public',
      Accept: 'application/json',
    }

    if (token.accessToken) {
      headers.Authorization = `Bearer ${token.accessToken}`
    }

    const req = { method, headers, body: JSON.stringify(body) }
    if (process.env.NODE_ENV !== 'production') {
      console.log('API Request:', { url, body })
    }

    const resp = await fetch(url, req)
    if (resp.status >= 300) {
      const respJson = await resp.json()
      return handleError(respJson, { session, local, method, url, body })
    }

    if ([101, 204, 205, 304].includes(resp.status)) return [true]

    const respJson = await resp.json()
    if (process.env.NODE_ENV !== 'production') {
      console.log('API Response:', respJson)
    }

    return [true, respJson]
  } catch (e) {
    return handleError(e, { session, local, method, url, body })
  } finally {
    if (session) session.dispatch({ type: LOADING_HIDE })
  }
}

async function handleError(resp, { session, local, method, url, body }) {
  console.error(resp)

  const message = resp.message || 'error.internal'

  if (message === 'token.expired') {
    local.state.token = await refreshToken({ session, local })
    local.dispatch({ type: TOKEN_SET, token: local.state.token })
    return request({ session, local, method, url, body })
  }

  if (message === 'token.invalid') {
    local.dispatch({ type: TOKEN_REMOVE })
  }

  if (session) {
    session.dispatch({ type: ALERT_ADD, item: { type: 'error', message } })
  }

  return [false, message]
}

export async function refreshToken({ session, local }) {
  const { staff, token } = local.state
  const exp = staff?.exp
  const now = Math.floor(new Date().getTime() / 1000)
  if (now > exp) return { accessToken: '', refreshToken: '' }

  const [ok, data] = await request({
    session,
    local,
    url: process.env.REACT_APP_TOKEN_URL,
    body: {
      action: 'refreshStaff',
      data: { refreshToken: token.refreshToken },
    },
  })
  if (!ok) return { accessToken: '', refreshToken: '' }

  return data
}
