import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Layout, ConfirmModal } from 'containers'
import { LoadingLogo, LoadingBar, Notification } from 'components'
import Login from 'views/Login/Login'

const StaffList = lazy(() => import('views/StaffList/StaffList'))
const WarehouseList = lazy(() => import('views/WarehouseList/WarehouseList'))
const CategoryList = lazy(() => import('views/CategoryList/CategoryList'))
const ProductList = lazy(() => import('views/ProductList/ProductList'))
const ProductPage = lazy(() => import('views/ProductPage/ProductPage'))
const ShelfList = lazy(() => import('views/ShelfList/ShelfList'))
const ShelfPage = lazy(() => import('views/ShelfPage/ShelfPage'))
const BuyList = lazy(() => import('views/BuyList/BuyList'))
const BuyPage = lazy(() => import('views/BuyPage/BuyPage'))
const AdjustList = lazy(() => import('views/AdjustList/AdjustList'))
const AdjustPage = lazy(() => import('views/AdjustPage/AdjustPage'))
const ProcessList = lazy(() => import('views/ProcessList/ProcessList'))
const ProcessPage = lazy(() => import('views/ProcessPage/ProcessPage'))
const InventoryList = lazy(() => import('views/InventoryList/InventoryList'))
const MerchantPage = lazy(() => import('views/MerchantPage/MerchantPage'))
const PromoList = lazy(() => import('views/PromoList/PromoList'))

const App = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingLogo />}>
        <Routes>
          <Route index path="/login" element={<Login />} />
          <Route path="/" element={<Layout />}>
            <Route path="staff" element={<StaffList />} />
            <Route path="warehouse" element={<WarehouseList />} />
            <Route path="category" element={<CategoryList />} />
            <Route path="category/:id" element={<CategoryList />} />
            <Route path="product" element={<ProductList />} />
            <Route path="product/:profile" element={<ProductPage />} />
            <Route path="product/:id/:profile" element={<ProductPage />} />
            <Route path="shelf" element={<ShelfList />} />
            <Route path="shelf/:profile" element={<ShelfPage />} />
            <Route path="shelf/:id/:profile" element={<ShelfPage />} />
            <Route path="buy" element={<BuyList />} />
            <Route path="buy/:profile" element={<BuyPage />} />
            <Route path="buy/:id/:profile" element={<BuyPage />} />
            <Route path="adjust" element={<AdjustList />} />
            <Route path="adjust/:profile" element={<AdjustPage />} />
            <Route path="adjust/:id/:profile" element={<AdjustPage />} />
            <Route path="process" element={<ProcessList />} />
            <Route path="process/:profile" element={<ProcessPage />} />
            <Route path="process/:id/:profile" element={<ProcessPage />} />
            <Route path="inventory" element={<InventoryList />} />
            <Route path="merchant/edit" element={<MerchantPage />} />
            <Route path="promo" element={<PromoList />} />
          </Route>
        </Routes>
      </Suspense>
      <LoadingBar />
      <Notification />
      <ConfirmModal />
    </BrowserRouter>
  )
}

export default App
